import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import SecondaryHero from "../../components/secondary-hero";
import EventSection from "../../components/event-section";

const BecomingAVolunteerPage = ({ transitionStatus }) => {
	const data = useStaticQuery(graphql`
		query {
			heroImgMain: wpMediaItem(title: { eq: "Home Hero Main" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			wpPageContent(id: { eq: "cG9zdDo0NzQ=" }) {
				content
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Volunteering",
				item: {
					url: `${siteUrl}/volunteering`,
					id: `${siteUrl}/volunteering`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Becoming a Volunteer",
				item: {
					url: `${siteUrl}/volunteering/becoming-a-volunteer`,
					id: `${siteUrl}/volunteering/becoming-a-volunteer`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Becoming a Volunteer | Friends of Eastbourne Hospital"
				description="Volunteers are the mainstay of the work of the Friends of Eastbourne Hospital; the organisation could not function without their help and support."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/volunteering/becoming-a-volunteer`,
					title: "Becoming a Volunteer | Friends of Eastbourne Hospital",
					description:
						"Volunteers are the mainstay of the work of the Friends of Eastbourne Hospital; the organisation could not function without their help and support.",
					images: [
						{
							url: `${data.heroImgMain?.localFile.publicURL}`,
							width: `${data.heroImgMain?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImgMain?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImgMain?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section className="pb-5">
					<SecondaryHero
						title="Becoming a volunteer"
						description={
							<span>
								Volunteers are the mainstay of the work of the Friends of
								<br className="d-none d-md-block" />
								Eastbourne Hospital; the organisation could not function without
								<br className="d-none d-md-block" />
								their help and support.
							</span>
						}
					/>
				</section>
				<section className="pb-md-7 pb-5">
					<Container>
						<Row>
							<Col className="page-content becoming-a-volunteer">
								{parse(data.wpPageContent.content)}
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					<EventSection />
				</section>
			</Layout>
		</>
	);
};

export default BecomingAVolunteerPage;
